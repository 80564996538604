import React from 'react';
import classnames from 'classnames';
import { galaxus, survey } from 'styles/pages/galaxus/index.module.scss';
const GalaxusSurvey = () => {
  return (
    <div className={classnames(galaxus, survey)}>
      <img
        src="https://wp.screver.com/wp-content/uploads/2023/08/galaxus-header.png"
        alt=""
      />
      <iframe
        title="Galaxus survey"
        width={'100%'}
        height={'800px'}
        src="https://go.screver.com/survey?token=d628ade4-361a-40de-8574-ae587567f0ac&surveyItem=64d20517f9e62876b3519bd4&value=4&lang=en"
      ></iframe>
      <img
        src="https://wp.screver.com/wp-content/uploads/2023/08/galaxus-footer.png"
        alt=""
      />
    </div>
  );
};

export default GalaxusSurvey;
